.Modal {
  z-index: 4;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  position: fixed;
  overflow: auto;
  opacity: 0;
  transition: 0.2s all;
  pointer-events: none;
}

.Modal.entered {
  pointer-events: all;
  opacity: 1;
}

.Modal__inner {
  transition: 0.2s all;
  padding: 50px 60px;
  margin: 0 16px 100px 16px;
  position: relative;

  border-radius: 12px;
  background: #fff;
  transform: scale(0.5);
  box-shadow: 2px 4px 6px 0px #00000026;
}

.entered .Modal__inner {
  transform: scale(1);
}

.Modal__wrap {
  transition: 0.2s all;
  width: 100%;
  top: 10%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Modal__controls {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  top: 20px;
  right: 20px;
  position: absolute;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

@media screen and (max-width: 576px) {
  .Modal__inner {
    padding: 40px 16px 32px 16px;
  }
}

@media screen and (max-height: 800px) {
  .Modal__wrap {
    top: 4%;
  }
}

@media screen and (max-height: 750px) {
  .Modal__wrap {
    top: 2%;
  }
}

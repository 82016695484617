.Auth {
  max-width: 520px;
}

.Auth__title {
  text-align: center;
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 10px;
}

.Auth__subTitle {
  text-align: center;
  margin-bottom: 25px;
}

.Auth__resetPass {
  color: var(--gray-color);
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.Auth__passwordWrap {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.Auth__passwordWrap .Auth__resetPass span {
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
}

.Auth__passwordWrap .Auth__resetPass span:hover {
  color: #000;
}

.Auth__methods {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.Auth__method {
  height: 48px;
  width: 100%;
  border: 1px solid #e3e3e3;
}

.Auth__method.OutlineBtn:disabled {
  border: 1px solid #e3e3e3;
}

.Auth__method img {
  width: 24px;
}

.Auth__input {
  border-radius: 12px;
  width: 100%;
}

.Auth__inputBlock {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.Auth__inputBtn {
  height: 46px;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
}

.Auth__inputBtn .loader {
  margin: 2px 0;
  width: 40px;
  position: relative;
  left: 0;
  top: 0;
  transform: none;
  --_g: no-repeat radial-gradient(circle closest-side, #fff 90%, #0000);
}

.Auth__desc {
  width: 100%;
  text-align: center;
  margin-bottom: 25px;
  color: #9892a6;
  font-size: 12px;
  font-weight: 300;
}

.Auth__authbtn {
  text-align: center;
  width: 100%;
}

.Auth__authbtn span {
  cursor: pointer;
  color: var(--accent-orange-62);
  text-decoration: underline;
}

.Auth__authbtn span:hover {
  color: var(--secondary-color);
}

.Auth__separatorBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}

.Auth__separator {
  width: 100%;
  height: 1px;
  background-color: #e5e8eb;
}

.Auth__separator_text {
  width: 100%;
  max-width: 50px;
  text-align: center;
  line-height: 22px;
}

.Auth__methods {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.Auth__method {
  max-width: 35%;
}

.Auth__method img {
  width: 24px;
}

.Auth__method .loader {
  --_g: no-repeat
    radial-gradient(circle closest-side, var(--accent-orange-62) 90%, #0000);
}

@media screen and (max-width: 576px) {
  .Auth__title {
    margin-bottom: 24px;
  }

  .Auth__subTitle {
    margin-bottom: 24px;
  }

  .Auth__inputBtn {
    margin-bottom: 16px;
  }

  .Auth__inputBlock {
    gap: 16px;
  }

  .Modal__inner {
    padding: 40px 16px 32px 16px;
  }

  .Auth__methods {
    gap: 5px;
  }
}

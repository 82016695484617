@import "katex/dist/katex.min.css";

:root {
  /* Old colors start */
  --main-color: #ffab40;
  --secondary-color: #ffbf6d;
  --gray-color: #8f8f8f;
  --blue-color: #2864ff;
  /* Old colors end */

  /* Colors start */
  --accent-orange-62: #ff8d3b;
  --accent-orange-92: #fff0e5;

  --blue-58: #2864ff;
  --blue-80: #99b5ff;
  --blue-96: #ebf0ff;
  --blue-98: #f5f8ff;

  --error-red-52: #d93041;
  --notification-green-32: #1f8246;

  --system-dark-0: #000000;
  --system-dark-5: #0a0d0f;
  --system-dark-16: #212931;
  --system-dark-44: #5a7087;
  --system-dark-67: #9aabbc;
  --system-dark-75: #b2bfcc;
  --system-dark-80: #c2ccd6;
  --system-dark-90: #e0e6eb;
  --system-dark-94: #edf0f3;
  --system-dark-98: #f9fafb;
  --system-dark-100: #ffffff;
  /* Colors end */
}

/* Fonts import start */

@font-face {
  font-family: "RF Dewi Extended";
  src: local("RF Dewi Extended"),
    url("../public/fonts/RF-Dewi-Extended-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "RF Dewi Extended";
  src: local("RF Dewi Extended"),
    url("../public/fonts/RF-Dewi-Extended-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "RF Dewi Extended";
  src: local("RF Dewi Extended"),
    url("../public/fonts/RF-Dewi-Extended-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "RF Dewi Extended";
  src: local("RF Dewi Extended"),
    url("../public/fonts/RF-Dewi-Extended-Bold-Italic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

/* Fonts import end */

/* CSS reset start */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

a {
  text-decoration: none;
}

nav ul {
  list-style: none;
}

/* CSS reset end */

body {
  overflow: hidden;
  height: 100vh;

  color: var(--system-dark-16);
  font-family: "Source Sans 3", sans-serif;
  /* font-size: 21px; */
  font-size: 16px;
  font-weight: 400;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: var(--system-dark-90);
}

::-webkit-scrollbar-corner {
}

/* Text styles start */

.Text-XS {
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
}

.Text-S {
  font-size: 12px;
  /* font-size: 18px; */
  font-weight: 400;
  /* line-height: 21px; */
  line-height: 14px;
}

.Text-M {
  font-size: 16px;
  /* font-size: 21px; */
  font-weight: 400;
  /* line-height: 24px; */
  line-height: 18px;
}

.Text-L {
  font-size: 24px;
  font-weight: 400;
  line-height: 27px;
}

.Text-XL {
  font-size: 27px;
  font-weight: 400;
  line-height: 30px;
}

.Text-2XL {
  font-size: 30px;
  font-weight: 400;
  line-height: 33px;
}

.Text-3XL {
  font-size: 33px;
  font-weight: 400;
  line-height: 36px;
}

.Text-L-Bold {
  /* font-size: 24px; */
  font-size: 18px;
  font-weight: 600;
  /* line-height: 27px; */
}

@media screen and (max-width: 768px) {
  .Text-XS {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
  }

  .Text-S {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
  }

  .Text-M {
    font-size: 17px;
    font-weight: 400;
    line-height: 20px;
  }

  .Text-M-Bold {
    font-size: 17px;
    font-weight: 600;
    line-height: 20px;
  }
}

/* Text styles end */

/* Buttons start */

button,
.btn {
  cursor: pointer;
  user-select: none;
  background: none;

  font-family: "Source Sans 3", sans-serif;
  font-size: 16px;
  /* font-size: 21px; */
  font-weight: 400;
  /* line-height: 24px; */

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 12px 18px;
  border-radius: 12px;
}

.SolidBtn {
  color: var(--system-dark-100);
  background-color: var(--system-dark-16);
}

.SolidBtn:hover:not([disabled]) {
  background-color: var(--system-dark-5);
}

.SolidBtn:disabled {
  background-color: var(--system-dark-67);
  cursor: initial;
}

.OutlineBtn {
  padding: 10px 16px;
  color: var(--system-dark-16);
  border: 1.5px solid var(--system-dark-75);
}

.OutlineBtn:hover {
  border: 1.5px solid var(--system-dark-67);
}

.OutlineBtn:disabled {
  border: 1.5px solid var(--system-dark-67);
  cursor: initial;
}

button.XL {
  font-size: 30px;
  font-weight: 400;
  line-height: 33px;
}

button.L {
  font-size: 24px;
  font-weight: 400;
  line-height: 27px;
}

button.M {
  font-size: 16px;
  /* font-size: 21px; */
  font-weight: 400;
  line-height: 24px;
}

button.S {
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
}

button.XS {
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
}

/* Buttons end */

.bold {
  font-weight: 600;
}

.link {
  font-weight: 400;
  cursor: pointer;
  text-decoration-line: underline;
}

input {
  outline: none;
  border: 1.5px solid var(--system-dark-90);
  font-family: "Source Sans 3", sans-serif;
}

input::placeholder {
  color: var(--system-dark-67);
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  font-family: "Source Sans 3", sans-serif;
}

.Input {
  border-radius: 12px;
  /* line-height: 22px; */
  font-size: 16px;
  /* font-size: 18px; */
  font-weight: 400;
  width: 100%;
  padding: 11px 12px;
}

.Input:focus {
  outline: none;
  border: 1px solid #8a8a8a;
}

.passwordInput {
  display: flex;
  align-items: center;
  position: relative;
}

.Error .Input,
.Input.Error {
  border: 1px solid #ff6868;
}

.passwordHideBtn {
  width: 24px;
  height: 24px;
  cursor: pointer;
  right: 12px;
  position: absolute;
}

.Footer {
  margin: 60px 0 40px 0;
}

.Footer__text {
  font-size: 14px;
  color: #fff !important;
}

/* Оверрайд дефолтного стиля toastify - start */

:root {
  --toastify-color-error: var(--error-red-52) !important;
  --toastify-color-info: var(--system-dark-16) !important;
  --toastify-color-success: var(--notification-green-32) !important;
}

.Toastify__toast {
  box-shadow: 0px 4px 20px 0px #00000014 !important;

  align-items: center;
  font-family: "Source Sans 3", sans-serif !important;
  font-size: 20px !important;
  line-height: 23px !important;

  font-weight: 400 !important;
  min-height: auto !important;
  gap: 12px;

  border-radius: 12px !important;
  padding: 12px 12px 12px 18px !important;
  color: var(--system-dark-100) !important;
}

.Toastify__toast > svg {
  align-self: flex-start;
}

.Toastify__toast-container--top-right {
  max-width: 420px !important;
  width: 100% !important;
  top: 40px !important;
  right: 40px !important;
  padding: 0 !important;
}

.Toastify__toast-body {
  margin: 0 !important;
  padding: 0 !important;
}

.toastify__close {
  width: 100%;
  max-width: 24px;
  height: 24px;
}
.Toastify__toast-icon {
  width: 24px !important;
}

.Tooltip {
  max-width: 450px;
  position: relative;
  z-index: 6;
  background-color: #ffffff;
  border-radius: 6px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.Tooltip__body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.Tooltip__body_title {
  font-size: 20px;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .Tooltip__body_title {
    font-size: 18px;
    font-weight: 600;
  }
  .Tooltip__body_text {
    font-size: 14px;
  }
  .__floater.__floater__open {
    padding-right: 10px !important;
  }
  .Tooltip__pagination_controls button {
    font-size: 16px;
  }
}
.Tooltip__pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Tooltip__pagination_text {
  user-select: none;
  color: #8f8f8f;
  font-size: 12px;
}

.Tooltip__pagination_controls {
  display: flex;
  align-items: center;
  gap: 4px;
}

.Tooltip__pagination_controls .prev {
  cursor: pointer;
  transform: rotate(-180deg);
}
.Tooltip__pagination_controls .next {
  cursor: pointer;
  transform: rotate(0deg);
}

.__floater.__floater__open {
  max-width: 90% !important;
}

.Tooltip__exitbtn {
  height: 24px;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 15px;
}

.Tooltip__body_text p {
  text-align: left;
}

.Tooltip__body_text li {
  margin-bottom: 6px;
}

.CloseTutorial__btn {
  cursor: pointer;
  z-index: 9999;
  top: 50px;
  transform: translateX(-50%);
  left: 50%;
  position: absolute;
  padding: 15px;
  border-radius: 6px;
  color: #fff;
  border: 1px solid #ffffff;
  font-size: 16px;
  font-weight: 600;
  max-width: 350px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-joyride__spotlight,
.__floater .__floater__open {
  transition: 0.1s !important;
}

.react-joyride-center {
  position: absolute;
  top: 50%;
  left: 50%;
}

.Message__body code {
  /* font-size: 15px !important; */
}

.Maintenance__mode {
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 22px;
  font-weight: 600;
  padding: 10px 14px;
  border-radius: 6px;
  color: #fff;
  z-index: 5;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ff6868d2;
  position: absolute;
  backdrop-filter: blur(2px);
}

.Profile__menu {
  height: 24px;
  cursor: pointer;
  margin: 0 auto;
}

/* Оверрайд дефолтного стиля toastify - end */

@media screen and (max-width: 768px) {
  .Toastify__toast-container {
    margin: 0 18px !important;
    top: 18px !important;
    left: 0px !important;
  }
  .Toastify__toast {
    width: calc(100vw - 36px);
  }
}

.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--pulse-color, var(--accent-orange-62));
  box-shadow: 9999px 0 0 -5px var(--pulse-color, var(--accent-orange-62));
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--pulse-color, var(--accent-orange-62));
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px var(--pulse-color, var(--accent-orange-62));
  animation: dot-pulse-before 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px var(--pulse-color, var(--accent-orange-62));
  animation: dot-pulse-after 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px var(--pulse-color, var(--accent-orange-62));
  }
  30% {
    box-shadow: 9999px 0 0 2px var(--pulse-color, var(--accent-orange-62));
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px var(--pulse-color, var(--accent-orange-62));
  }
}

@keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px var(--pulse-color, var(--accent-orange-62));
  }
  30% {
    box-shadow: 9984px 0 0 2px var(--pulse-color, var(--accent-orange-62));
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px var(--pulse-color, var(--accent-orange-62));
  }
}

@keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px var(--pulse-color, var(--accent-orange-62));
  }
  30% {
    box-shadow: 10014px 0 0 2px var(--pulse-color, var(--accent-orange-62));
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px var(--pulse-color, var(--accent-orange-62));
  }
}

.loader__wrap {
  transition: 0.15s all;
  z-index: -1;
  opacity: 0;
  left: 0;
  right: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}

.loader__wrap.active {
  z-index: 2;
  opacity: 1;
}

.loader {
  position: absolute;
  left: 50%;
  top: 30%;
  transform: translateX(-50%);
  width: 60px;
  aspect-ratio: 2;
  --_g: no-repeat
    radial-gradient(circle closest-side, var(--accent-orange-62) 90%, #0000);
  background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
  background-size: calc(100% / 3) 50%;
  animation: l3 1s infinite linear;
}

button .loader {
  left: 0;
  top: 0;
  position: relative;
  width: 40px;
  transform: none;
  --_g: no-repeat radial-gradient(circle closest-side, #fff 90%, #0000);
}

@keyframes l3 {
  20% {
    background-position: 0% 0%, 50% 50%, 100% 50%;
  }
  40% {
    background-position: 0% 100%, 50% 0%, 100% 50%;
  }
  60% {
    background-position: 0% 50%, 50% 100%, 100% 0%;
  }
  80% {
    background-position: 0% 50%, 50% 50%, 100% 100%;
  }
}
